import React, {useEffect, useState} from "react";
import {Chart as ChartJS, ArcElement, Tooltip, Legend} from "chart.js";
import {Chart, Doughnut} from "react-chartjs-2";
import useWindowSize from "../../hooks/useWindowSize";

ChartJS.register(Tooltip, Legend, ArcElement);

export default function PieChartComponent({setChartLabel, setChartElement}) {
    const [cutOut, seCutOut] = useState(100);

  const [innerWidth, innerHeight] = useWindowSize();
  useEffect(() => {
    if (innerWidth <= 600) {
      seCutOut(60);
    } else {
      seCutOut(90);
    }
  }, [innerWidth]);
  const data = {
    labels: [
      "#FFFF00",
      "#FF0000",
      "#0000FF",
      "#008000",
      "#964B00",
      "#FF9500",
      "#4B0082",
      "#000000"
      
    ],
    datasets: [
      {
        label: "Your Attendance",
        data: [1, 1, 1, 1, 1, 1, 1,1],
        backgroundColor: [
          "#FFFF00",
          "#FF0000",
          "#0000FF",
          "#008000",
          "#964B00",
          "#FF9500",
          "#4B0082",
          "#000000"
        ],
        borderColor: [
          "#FFFF00",
          "#FF0000",
          "#0000FF",
          "#008000",
          "#964B00",
          "#FF9500",
          "#4B0082",
          "#000000"
        ],
        borderWidth: 1,
        // radius:80,
        cutout: cutOut,
        offset: 2,
        hoverOffset: 15,
    }]};

    const tooltip = {
        callbacks: {
            title: function (chart) {
                setChartLabel(chart[0].label);
                // console.log(chart);
            },
        }};

    const options = {
        onHover: (event, chartElement) => {
            setChartElement(chartElement);
            // console.log('event,chartElement',event,chartElement)
        },
        maintainAspectRatio: false,
        events: ["click"],
        layout: {
            padding: 10,
        },
        length: {
            labels: {
                fontSize: "10px",
            },
        },
        plugins: {
            tooltip,
            label: {
                display: false,
            },
            // tooltip: {
            //   enabled: false // <-- this option disables tooltips
            // },
            legend: {
                display: false,
            },
            datalabels: {
                display: false,
            },
            title: {
                display: false,
            },
        },
    };

    return (
        <Doughnut data={data} height="87px" width="87px" options={options} />
    );
}
