import React, { useEffect, useState } from "react";
import "../styles/AboutUs.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHandshakeAngle,
  faChartLine,
  faFlaskVial,
} from "@fortawesome/free-solid-svg-icons";
import AOS from "aos";
import "aos/dist/aos.css";
import icon1 from '../assets/images/handshake.png'
import icon2 from '../assets/images/products.png'
import icon3 from '../assets/images/growth.png'

import ProgressBar from '../components/common/ProgressBar.jsx';
import bgk from "../assets/images/csr/bgk.jpg";
import ipad from "../assets/images/csr/ipad.jpg";
import tree from "../assets/images/csr/tree.jpg";
import wall from "../assets/images/csr/wall.jpg";
import water from "../assets/images/csr/water.jpg";
import { useNavigate } from "react-router-dom";
import AboutUsModal from "../components/common/AboutUsModal";

export default function AboutUs() {
  const [show, setShow] = useState(false);
  const [ind, setInd] = useState(0);
  const navigate = useNavigate();

  const data = [
    {
      name: "Idle Mind is a Devil's Workshop",
      details:
        "Produced over 1650 kits to make board games under INR20 and taught 500 underprivileged children aged 10-15 how to make them so that they can sell these and earn money during their summer holidays instead of being ‘idle’ and engaging in various notorious activities. ",
    },
    {
      name: "Tablet Distribution",
      details:
        "Digital India, a better India is something we strongly believe in. Therefore we decided we should provide these resources for those that are less fortunate and make their lives easier during the harsh online learning situation during the Covid -19 Pandemic.",
    },
    {
      name: "Geography in a walk",
      details:
        "Providing resources and man power to paint the exterior walls of a public highschool for  underprivileged children to teach them the basics of geography starting with basic concept like the milky way, solar system, earth, asia, india, Maharashtra and mumbai to keep them informed of where they stay and also help them fetch their basic passing marks for a geography exam paper",
    },
    {
      name: " Learning from Art",
      details:
        "Through our 'Learning from Art' initiative we created projects like ‘Grow More Trees’ and ‘Save Every drop of Water’ resulting in fun and engaging art projects which taught a social message to children with learning disabilities.",
    },
  ];

  useEffect(() => {
    AOS.init({
      duration: 1700,
    });
    // AOS.refresh();
  }, []);
  const handlenavigate = () => {
    navigate("/contact");
  };
  return (
    <section className="container about-us-container container-custom mt-4">
      <div
        data-aos="fade-up"
        data-aos-duration="1700"
        className="about-us-banner-container"
      >
        <div className="about-us-banner-text">
          <h1> About Us</h1>
          <div className="about-us-text">
            We are a more than 3-decade-old company producing specialty
            chemicals for the oil industry. Presently, we are actively supplying
            our products globally, and the quality of the products has been
            customised to suit the individual needs of governments and
            companies. We offer solutions that cover regulatory requirements,
            branding, leak detection, product differentiation, tax compliance,
            and any other specific needs. The company is managed by
            professionals who place a premium on quality and service. 
          </div>
          <button onClick={handlenavigate} className="contact-us">
            Contact Us
          </button>
        </div>
        <div className="about-us-banner-img-container ms-0 ms-md-3 ms-lg-5">
          <img className="rounded" src="/interior/int6.jpg" alt="" />
        </div>
      </div>
      {/* .infographics  */}
      <div className="infographics mt-5">
        <h1>Infographics</h1>
        <p>
          These are our progress and milestone achievements in the past nine
          years, from 2005 to now. We are honoured to say that we are a
          stand-alone organisation in this industry.
        </p>

        <div className="infographics-progress-bar-cont">
          {/* 1 */}
          <div className="d-flex margin-bottom align-items-center">
            <p>2005</p>
            <div className="infographics-progress-bar-one">
              <ProgressBar done={47}  />
            </div>
          </div>
          {/* 2 */}
          <div className="d-flex margin-bottom align-items-center">
            <p>2010</p>
            <div className="infographics-progress-bar-two">
              <ProgressBar done={62} />
            </div>
          </div>
          {/* 3 */}
          <div className="d-flex margin-bottom align-items-center">
            <p>2015</p>
            <div className="infographics-progress-bar-three">
              <ProgressBar done={86}  />
            </div>
          </div>
          {/* 4 */}
          <div className="d-flex align-items-center">
            <p>2020</p>
            <div className="infographics-progress-bar-four">
              <ProgressBar done={91}  />
            </div>
          </div>
        </div>

        <div
          data-aos="fade-up"
          data-aos-duration="1700"
          className="infographics-rectangle-cont"
        >
          <div className="infographics-rectangle-cont-two">
            <div className="infographics-rectangle">
              <img src={icon1}
                className="infographics-icon"
             
              />
            </div>
            <p>
              {" "}
              18+ <br/> Presence in Countries 
            </p>
          </div>
          <div className="infographics-rectangle-cont-two">
            <div className="infographics-rectangle">
              <img src={icon2}
                className="infographics-icon"
              />
            </div>
            <p>
              {" "}
              15+ <br /> Products
            </p>
          </div>
          <div className="infographics-rectangle-cont-two">
            <div className="infographics-rectangle">
              <img
                className="infographics-icon"
                src={icon3}
              />
            </div>
            <p>
              {" "}
              30+ <br /> Years of Experience
            </p>
          </div>
        </div>
      </div>
      {/* <p>
              {" "}
              presence in over 10 countries <br />
            </p>
          </div> */}
      {/* <div className="infographics-rectangle-cont-two">
            <div className="infographics-rectangle">
              <FontAwesomeIcon
                className="infographics-icon"
                icon={faFlaskVial}
              />
            </div>
            <p>
              {" "}
              15 <br /> Products
            </p>
          </div>
          <div className="infographics-rectangle-cont-two">
            <div className="infographics-rectangle">
              <FontAwesomeIcon
                className="infographics-icon"
                icon={faChartLine}
              />
            </div>
            <p>
              {" "}
              15+ <br /> Years of Experience
            </p>
          </div>
        </div>
      </div> */}

      <div className="corporate-social-responsibility">
        <h1>Corporate Social Responsibility</h1>
        <p>
          {/* What Is Corporate Social Responsibility (CSR)? Corporate social
          responsibility (CSR) is a self-regulating business model that helps a
          company be socially accountable to itself, its stakeholders, and the
          public. By practicing corporate social responsibility, also called
          corporate citizenship, companies can be conscious of the kind of
          impact they are having on all aspects of society, including economic,
          social, and environmental. */}
          Cooperate Social responsibility is an aspect of business we consider
          critical in this day and age, and one we keep as a top priority for
          our company, as we believe "the more we give back, the more we get."
          We've been personally involved in activities in various sectors to
          contribute towards the greater good of society.
          {/* 1) Idle Mind is a
          Devil's Workshop: (Board game kit don’t add this it’s  for your ref) (add
          pic of the board game, not me) Produced over 1650 kits to make the board
          games under INR20 and taught 500 underprivileged children aged 10-15
          how to make them so that they can sell these and earn money during
          their summer holidays instead of being ‘idle’ and engaging in various
          notorious activities. */}
        </p>
        <h2 className="csr-text">Our CSR Activities</h2>

        <div className="csr-card-container">
          <div
            onClick={() => {
              setShow(true);
              setInd(0);
            }}
            className="csr-card"
          >
            <div className="csr-card-img">
              <img src={bgk} height="100%" width="auto" alt="" />
            </div>
            <p>Idle Mind is a Devil's Workshop</p>
          </div>
          <div
            onClick={() => {
              setShow(true);
              setInd(1);
            }}
            className="csr-card"
          >
            <div className="csr-card-img">
              <img src={ipad} height="100%" width="auto" alt="" />
            </div>
            <p>Tablet Distribution </p>
          </div>
          <div
            onClick={() => {
              setShow(true);
              setInd(2);
            }}
            className="csr-card"
          >
            <div className="csr-card-img">
              <img src={water} height="100%" width="auto" alt="" />
            </div>
            <p>Geography in a walk</p>
          </div>
          <div
            onClick={() => {
              setShow(true);
              setInd(3);
            }}
            className="csr-card"
          >
            <div className="csr-card-img">
              <img src={tree} height="100%" width="auto" alt="" />
            </div>
            <p>Learning from Art</p>
          </div>
          {/* <div className="csr-card">
            <div className="csr-card-img">
              <img
                src={tree}
                height="100%"
                width="auto"
                alt=""
              />
            </div>
            <p>Solar Panels</p>
          </div> */}
          {/* <div className="csr-card">
            <div className="csr-card-img">
              <img src={wall} height="100%" width="auto" alt="" />
            </div>
            <p>Wall Painting</p>
          </div> */}
        </div>
      </div>
      <AboutUsModal show={show} setShow={setShow} data={data[ind]} />
    </section>
  );
}
