export const productsList=[
  {
    name:'CHEMOCID YELLOW G LIQUID',
    color:"#FFFF00",
    image:"/product/y1.JPG"
    
  },
  {
    name:'CHEMOCID YELLOW R LIQUID',
    color:"#FFFF00",
    image:"/product/y3.JPG"
    
  },
  {
    name:'CHEMOCID YELLOW PDR',
    color:"#FFFF00",
    image:"/product/y.JPG"
    
  },
  {
    name:'CHEMOCID RED B LIQUID',
    color:"#FF0000",
    image:"/product/r1.JPG"
    
  },
  {
    name:'CHEMOCID RED G LIQUID',
    color:"#FF0000",
    image:"/product/r.JPG"
    
  },
  {
    name:'CHEMOCID RED HS',
    color:"#FF0000",
    image:"/product/r3.JPG"
    
  },
  {
    name:'CHEMOCID BLUE M LIQUID',
    color:"#0000FF",
    image:"/product/b1.JPG"
    
  },
  {
    name:'CHEMOCID BLUE GA LIQUID',
    color:"#0000FF",
    image:"/product/b.JPG"
    
  },
  
  {
    name:'CHEMOCID BROWN G LIQUID',
    color:"#964B00",
    image:"/product/br.JPG"
    
  }
  ,
  {
    name:'CHEMOCID BLACK FMD LIQUID',
    color:"#000000",
    image:"/product/bl.JPG"
    
  }
  ,
  {
    name:'CHEMOCID GREEN M LIQUID',
    color:"#008000",
    image:"/product/g.JPG"
    
  }
  ,
  {
    name:'CHEMOCID GREEN B LIQUID',
    color:"#008000",
    image:"/product/g1.JPG"
    
  }
  ,
  {
    name:'CHEMOCID ORANGE B LIQUID',
    color:"#FFA500",
    image:"/product/o1.JPG"
    
  }
  ,
  {
    name:'CHEMOCID ORANGE G LIQUID',
    color:"#FFA500",
    image:"/product/o.JPG"
    
  }
  ,
  {
    name:'CHEMOCID PURPLE LIQUID',
    color:"#4B0082",
    image:"/product/p1.JPG"
    
  }
]

