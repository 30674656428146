import React, { useEffect } from "react";
import AOS from "aos";
import { ImLeaf } from "react-icons/im";
import { BsFillPatchCheckFill } from "react-icons/bs";
import "aos/dist/aos.css";

export default function OurMotto() {
  useEffect(() => {
    AOS.init({
      duration: 1700,
    });
    // AOS.refresh();
  }, []);
  return (
    <section
      data-aos="fade-up"
      data-aos-duration="1700"
      className="container our-motto-container container-custom my-5"
    >
      <h1 className="mb-5">Our Motto</h1>
      <p>
        We are committed to developing new and advanced products while
        maintaining the highest level of quality and customer satisfaction and
        keeping the environment in mind.
        <br />
        <BsFillPatchCheckFill /> Quality: Highest quality of raw materials,
        specially picked from factories with similar control checks.
        <br />
        <ImLeaf />
        Sustainability: inclusive of an in-house effluent treatment plant,
        Chemocid ensures a zero discharge policy. Specific and individual needs
      </p>
    </section>
  );
}
