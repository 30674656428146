import React, { useEffect, useState } from "react";
import "../styles/OurFacilities.css";
import medical from "../assets/images/medical-research.png";
import instru from "../assets/images/instrument.png";
import AOS from "aos";
import "aos/dist/aos.css";
import { OurFacility } from "../OurFacility";
import {Carousel} from "react-bootstrap";

export default function OurFacilities() {
  const [displayData, setDisplayData] = useState([
    "/office/office2.jpg",
    "/office/office3.jpg",
    "/office/office4.jpg",
    "/office/office5.jpg",
    "/office/office6.jpg",
  ]);
  const [chip, setChip] = useState(1);
  useEffect(() => {
    AOS.init();
    // AOS.refresh();
  }, []);

  const handleChipChange = (value) => {
    setChip(value);
    if (value === 2) {
      setDisplayData([
        // "/interior/int1.jpg",
        "/interior/int2.jpg",
        "/interior/int3.jpg",
        // "/interior/int4.jpg",
        "/interior/int6.jpg",
        "/interior/int7.jpg",
        // "/interior/int8.jpg",
        "/exterior/ext1.jpeg",
        "/exterior/ext3.jpeg",
        "/exterior/ext4.jpeg",
      ]);
    } 
    else if (value === 1) {
      setDisplayData([
        "/office/office2.jpg",
        "/office/office3.jpg",
        "/office/office4.jpg",
        "/office/office5.jpg",
        "/office/office6.jpg",
      ]);
    }
  };

  return (
    <section
      data-aos="fade-up"
      data-aos-duration="1700"
      className="our-facilities-container container-custom"
    >
      <h1>Our Facilities</h1>
      <div className="our-facilities-chip-container">
        {/* <div onClick={()=>handleChipChange(0)} className={chip===0?"our-facilities-chip-selected":"our-facilities-chip"}>Laboratory</div> */}
        <div
          onClick={() => handleChipChange(1)}
          className={
            chip === 1 ? "our-facilities-chip-selected" : "our-facilities-chip"
          }
        >
          Office Space
        </div>

        <div
          onClick={() => handleChipChange(2)}
          className={
            chip === 2 ? "our-facilities-chip-selected-production" : "our-facilities-chip-production"
          }
        >
          Production Facility
        </div>

      </div>

      <div className="show-carousel">
        <Carousel indicators={false} interval={null} >
          <Carousel.Item>
          <div
          onClick={() => handleChipChange(1)}
          className={
            chip === 1 ? "our-facilities-chip-selected" : "our-facilities-chip"
          }
        >
          Office Space
        </div>
          </Carousel.Item>
          <Carousel.Item>
          <div
          onClick={() => handleChipChange(2)}
          className={
            chip === 2 ? "our-facilities-chip-selected" : "our-facilities-chip"
          }
        >
          Production Facility
        </div>
          </Carousel.Item>
        </Carousel>
      </div>

      <div className="our-facilities-gallery-container">
        {displayData.map((d) => {
          return (
            <div className="our-facilities-img-container">
              <img src={d} alt="" className="" width="100%" />
            </div>
          );
        })}
      </div>

      {/* <div className="rectangle-container">
        <div className="rectangle">
          <div className="rectangle-image">
            <img src={medical} alt="" />
          </div>
          <p>{displayData?.name}</p>
        </div>
        <p>
         {displayData?.details}
        </p>
      </div> */}
      {/* <div  data-aos="fade-up" data-aos-duration="1700"  className="instrument-img">
        <img src={instru} alt="" />
      </div>

      <p  data-aos="fade-up" data-aos-duration="1700"  className="instrument-text">
      Its a ISO 9001 compliant plant having SS and Glass lined reactors in addition to its Mild Steel reactors to handle a variety of production/reactions. Dedicated ETP assists in achieving its production goals. With inhouse R&D laboratory the company is able to handle development of new products and also improve on production processes.
    With its installation of Solar PV and Thermal panels the company has strived to achieve a lower carbon footprint and do its part towards the environment.
      </p> */}
    </section>
  );
}
