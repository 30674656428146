export const countryDetails=[
  
{
    name:'France',
    details:' '
},
{
    name:'Netherlands',
    details:''
},
{
    name:'Italy',
    details:'  '
},
{
    name:'Turkey',
    details:'  '
},
{
    name:'UK',
    details:'  '
},
{
    name:'USA',
    details:'  '
},
{
    name:'Peru',
    details:'  '
},
{
    name:'Thailand',
    details:'  '
},
{
    name:'China',
    details:'  '
},
{
    name:'Singapore',
    details:'  '
},
{
    name:'Greece',
    details:'  '
},

]