import React, { useEffect, useState } from "react";
import "../styles/Contact.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faEnvelope, faCity } from "@fortawesome/free-solid-svg-icons";
import Slider from "../components/contact/Slider";
import { FaLinkedin } from "react-icons/fa";
import axios from "axios";
import AOS from "aos";
import "aos/dist/aos.css";

export default function Contact() {
  const [data, setData] = useState({
    name: "",
    email: "",
    product: "",
    company: "",
    message: "",
  });

  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
    // console.log(e.target.name,data)
  };

  const handleSubmit = (e) => {
    if (data.name && data.email && data.message) {
      axios
        .post(
          "https://sheet.best/api/sheets/efe64c8a-5281-4bd5-91df-778140ce53dc",
          data
        )
        .then((res) => {
          setError("");
          setSuccess("Your information sent successfully");
          setTimeout(() => {
            setSuccess("");
          }, 10000);
          // console.log(res)
          setData({
            name: "",
            email: "",
            product: "",
            company: "",
            message: "",
          });
        });
    } else {
      setSuccess("");

      setError("Please fill up missing field");
    }

    // e.preventDefault()
  };
  useEffect(() => {
    AOS.init({
      duration: 1700,
    });
    // AOS.refresh();
  }, []);

  return (
    <section className="contact-container container container-custom">
      <h1> Contact Us</h1>
      {error && <p className="error-text">{error}</p>}
      {success && <p className="success-text">{success}</p>}
      <div className="contact-input-container">
        <div className="contact-input">
          <div className="d-xl-flex ">
            <div className="first-two ">
              <label for="name">Name</label> <br />
              <input
                onChange={handleChange}
                type="text"
                id="name"
                name="name"
                value={data.name}
                required
              />{" "}
              <br />
              <label for="email">Work Email</label> <br />
              <input
                onChange={handleChange}
                type="email"
                id="email"
                name="email"
                value={data.email}
                required
              />
            </div>
            <div className="first-two">
              <label for="company">Company</label> <br />
              <input
                onChange={handleChange}
                type="text"
                id="company"
                name="company"
                value={data.company}
                required
              />{" "}
              <br />
              <label for="product">Product</label> <br />
              <input
                onChange={handleChange}
                type="text"
                id="product"
                name="product"
                value={data.product}
              />
            </div>
          </div>
          <label for="message">Message</label> <br />
          <textarea
            onChange={handleChange}
            type="text"
            id="message"
            name="message"
            value={data.message}
            rows="4"
            cols="10"
          />{" "}
          <br />
          <button onClick={handleSubmit} className="contact-submit">
            Submit
          </button>
        </div>
        <div className="contact-information">
          <p>
            {" "}
            <FontAwesomeIcon
              className="contact-information-icon"
              icon={faCity}
            />{" "}
            {/* Plot No 262, Sachin,<br/> G I D C, Surat, Gujarat 394230, India */}
            801A Eureka Towers,
            <br /> MindSpace, New Link Road, Malad West, Mumbai 400064
          </p>
          <p>
            <FontAwesomeIcon
              className="contact-information-icon"
              icon={faPhone}
            />{" "}
            +91 22 61433030
          </p>
          <p>
            <FontAwesomeIcon
              className="contact-information-icon"
              icon={faEnvelope}
            />{" "}
            info@chemocid.com
          </p>
          {/* <p className="follow-us">Follow Us</p> */}
          {/* <p className="let-connect">
          {" "}
          <FaLinkedin /> Let's Connect
        </p> */}
        </div>
      </div>

      <div>
        <h3 className="slider-title">Our Office</h3>
        <div className="slider-container">
          <Slider />
        </div>
      </div>
    </section>
  );
}
