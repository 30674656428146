import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Link } from "react-router-dom";
import {  FaBars } from "react-icons/fa";
import logo from '../../assets/images/logo.png'

export default function Navigation() {
  return (
    <Navbar className="navbar-container"  expand="lg">
      <Container fluid className="container-custom">
        <Navbar.Brand className="nav-brand" as={Link} to="/">
          <img src={logo} alt='Chemocid logo' height='100%'/>
        </Navbar.Brand>
        <Navbar.Toggle className="bread-btn" aria-controls="navbarScroll" >

        <FaBars className="bar"/>
        </Navbar.Toggle>
        <Navbar.Collapse id="navbarScroll">
          <Nav className="ms-auto my-2 my-lg-0" navbarScroll>
            <Nav.Link className="nav-item" as={Link} to="/">
              Home
            </Nav.Link>
            <Nav.Link className="nav-item" as={Link} to="/about">About us</Nav.Link>
            <Nav.Link className="nav-item" as={Link} to="/product">Products</Nav.Link>
            <Nav.Link className="nav-item" as={Link} to="/facilities">Our Facility</Nav.Link>
            <Nav.Link className="nav-item" as={Link} to="/contact">Contact Us</Nav.Link>\
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
