import React, { useEffect } from "react";
import product from "../../assets/images/product.png";
import AOS from "aos";
import "aos/dist/aos.css";
import { useNavigate } from "react-router-dom";

export default function Products() {
  const navigate = useNavigate();
  useEffect(() => {
    AOS.init({
      duration: 1700,
    });
    // AOS.refresh();
  }, []);
  const handleNavigate = () => {
    navigate("/product");
  };
  return (
    <div
      data-aos="fade-up"
      data-aos-duration="1700"
      className="home-products-container container-custom"
    >
      <div className="products-image-container">
        <img src={product} alt="" />
      </div>
      <div className="product-content">
        <h1>Products</h1>
        <p>
          The manufacturing plant is an ISO 9001-compliant facility with a
          dedicated in-house effluent treatment plant. Being a specialist in its
          product category, it is nimble in executing orders and requests
          promptly.
        </p>
        <button onClick={handleNavigate}>Know More</button>
      </div>
    </div>
  );
}
