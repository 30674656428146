import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import AOS from "aos";
import "aos/dist/aos.css";
import { countryDetails } from "../../Country-details";
import AnimatedNumber from "animated-number-react";

export default function OurGlobalPresence() {
  const [displayData, setDisplayData] = useState(countryDetails[1]);
  const [line, setLine] = useState(2);
  useEffect(() => {
    AOS.init();
    // AOS.refresh();
  }, []);

  const handleMapClick = (value) => {
    setLine(value);
    const filterData = countryDetails.filter((d, i) => i === value - 1);
    setDisplayData(filterData[0]);
  };
  console.log("line", line, displayData);
  return (
    <>
      <section
        data-aos="fade-up"
        data-aos-duration="1700"
        className="our-global-presence-container container-custom"
      >
        <h1> Our Global Presence</h1>
        <h4>
          <AnimatedNumber
            value="18"
            formatValue={(value) => value.toFixed(0)}
            duration="2000"
          />
          + Countries
        </h4>
        <br />
        <div className="country-description mt-5">
          <h5>{/* {displayData?.name} */}</h5>
          <p>{/* {displayData?.details} */}</p>
        </div>
      </section>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div
          data-aos="fade-up"
          data-aos-duration="1700"
          className="half-circle"
        >
          <div
            onMouseEnter={() => handleMapClick(1)}
            className="half-circle-icon-one"
          >
            {" "}
            <FontAwesomeIcon icon={faLocationDot} />
            <div
              className={
                line === 1
                  ? "half-circle-icon-line"
                  : "half-circle-icon-line-none"
              }
            >
              <div className="half-circle-icon-content">
                <h5>{displayData?.name}</h5>
              </div>
            </div>
          </div>
          <div
            onMouseEnter={() => handleMapClick(2)}
            className="half-circle-icon-two"
          >
            {" "}
            <FontAwesomeIcon icon={faLocationDot} />
            <div
              className={
                line === 2
                  ? "half-circle-icon-line-two"
                  : "half-circle-icon-line-none"
              }
            >
              <div className="half-circle-icon-content">
                <h5>{displayData?.name}</h5>
              </div>
            </div>
          </div>
          <div
            onMouseEnter={() => handleMapClick(3)}
            className="half-circle-icon-three"
          >
            {" "}
            <FontAwesomeIcon icon={faLocationDot} />
            <div
              className={
                line === 3
                  ? "half-circle-icon-line-three"
                  : "half-circle-icon-line-none"
              }
            >
              {" "}
              <div className="half-circle-icon-content">
                <h5>{displayData?.name}</h5>
              </div>
            </div>
          </div>
          <div
            onMouseEnter={() => handleMapClick(4)}
            className="half-circle-icon-four"
          >
            {" "}
            <FontAwesomeIcon icon={faLocationDot} />
            <div
              className={
                line === 4
                  ? "half-circle-icon-line-four"
                  : "half-circle-icon-line-none"
              }
            >
              {" "}
              <div className="half-circle-icon-content">
                <h5>{displayData?.name}</h5>
              </div>
            </div>
          </div>
          <div
            onMouseEnter={() => handleMapClick(5)}
            className="half-circle-icon-five"
          >
            {" "}
            <FontAwesomeIcon icon={faLocationDot} />
            <div
              className={
                line === 5
                  ? "half-circle-icon-line-five"
                  : "half-circle-icon-line-none"
              }
            >
              {" "}
              <div className="half-circle-icon-content">
                <h5>{displayData?.name}</h5>
              </div>
            </div>
          </div>
          <div
            onMouseEnter={() => handleMapClick(6)}
            className="half-circle-icon-six"
          >
            {" "}
            <FontAwesomeIcon icon={faLocationDot} />
            <div
              className={
                line === 6
                  ? "half-circle-icon-line-six"
                  : "half-circle-icon-line-none"
              }
            >
              {" "}
              <div className="half-circle-icon-content">
                <h5>{displayData?.name}</h5>
              </div>
            </div>
          </div>
          <div
            onMouseEnter={() => handleMapClick(7)}
            className="half-circle-icon-seven"
          >
            {" "}
            <FontAwesomeIcon icon={faLocationDot} />
            <div
              className={
                line === 7
                  ? "half-circle-icon-line-seven"
                  : "half-circle-icon-line-none"
              }
            >
              {" "}
              <div className="half-circle-icon-content">
                <h5>{displayData?.name}</h5>
              </div>
            </div>
          </div>
          <div
            onMouseEnter={() => handleMapClick(8)}
            className="half-circle-icon-eight"
          >
            {" "}
            <FontAwesomeIcon icon={faLocationDot} />
            <div
              className={
                line === 8
                  ? "half-circle-icon-line-eight"
                  : "half-circle-icon-line-none"
              }
            >
              {" "}
              <div className="half-circle-icon-content">
                <h5>{displayData?.name}</h5>
              </div>
            </div>
          </div>
          <div
            onMouseEnter={() => handleMapClick(9)}
            className="half-circle-icon-nine"
          >
            {" "}
            <FontAwesomeIcon icon={faLocationDot} />
            <div
              className={
                line === 9
                  ? "half-circle-icon-line-nine"
                  : "half-circle-icon-line-none"
              }
            >
              {" "}
              <div className="half-circle-icon-content">
                <h5>{displayData?.name}</h5>
              </div>
            </div>
          </div>
          <div
            onMouseEnter={() => handleMapClick(10)}
            className="half-circle-icon-ten"
          >
            {" "}
            <FontAwesomeIcon icon={faLocationDot} />
            <div
              className={
                line === 10
                  ? "half-circle-icon-line-ten"
                  : "half-circle-icon-line-none"
              }
            >
              <div className="half-circle-icon-content">
                <h5>{displayData?.name}</h5>
              </div>
            </div>
          </div>

          <div
            onMouseEnter={() => handleMapClick(11)}
            className="half-circle-icon-eleven"
          >
            {" "}
            <FontAwesomeIcon icon={faLocationDot} />
            <div
              className={
                line === 11
                  ? "half-circle-icon-line-eleven"
                  : "half-circle-icon-line-none"
              }
            >
              {" "}
              <div className="half-circle-icon-content">
                <h5>{displayData?.name}</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
