import Carousel from 'react-bootstrap/Carousel';
import img2 from '../../assets/images/office/office3.jpg'
import img3 from '../../assets/images/office/office4.jpg'
import img4 from '../../assets/images/office/office5.jpg'



const images=[ img2, img3,img4];

function Slider() {
  return (
    <Carousel>
     {images.map((d)=><Carousel.Item className='slide-img'>
        <img
          className="d-block w-75 img-fluid mx-auto"
          src={d}
          alt="First slide"
        />
      
      </Carousel.Item>) }
    </Carousel>
  );
}

export default Slider;