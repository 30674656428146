import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import Home from "./pages/Home";
import 'bootstrap/dist/css/bootstrap.min.css';
import AboutUs from "./pages/AboutUs";
import Navigation from "./components/common/Navigation";
import Footer from "./components/common/Footer";
import Contact from "./pages/Contact";
import Product from "./pages/Product";
import OurFacilities from "./pages/OurFacilities";

function App() {
  return (
    <div>
  <BrowserRouter>
  <Navigation/>
    <Routes>
      <Route path="/" element={<Home />}/>
      <Route path="/about" element={<AboutUs />}/>
      <Route path="/contact" element={<Contact />}/>
      <Route path="/product" element={<Product />}/>
      <Route path="/facilities" element={<OurFacilities />}/>
    </Routes>
    <Footer/>
  </BrowserRouter>
    </div>
  );
}

export default App;
