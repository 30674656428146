import React, { useEffect, useState } from "react";
import { FaGripfire } from "react-icons/fa";
import { RiSafeFill } from "react-icons/ri";
import {
  BsShieldFillCheck,
  BsCalendar2CheckFill,
  BsFillShieldLockFill,
} from "react-icons/bs";
import icon1 from '../../assets/images/regulation.png'
import icon2 from '../../assets/images/color.png'
import icon3 from '../../assets/images/shield.png'
import icon4 from '../../assets/images/protection.png'
import AOS from "aos";
import "aos/dist/aos.css";

import img1 from "../../assets/images/csr/bgk.jpg";
import img2 from "../../assets/images/csr/ipad.jpg";
import img3 from "../../assets/images/csr/tree.jpg";

export default function OurService() {
  const [rec, setRec] = useState(0);
  useEffect(() => {
    AOS.init({
      duration: 1700,
    });
    // AOS.refresh();
  }, []);
  return (
    <section
      data-aos="fade-up"
      data-aos-duration="1700"
      className="container our-service-container container-custom mt-5"
    >
      <h1> Our Service</h1>
      <p className="pb-3">
        We've been offering customised chemical solutions for over 30 years, and
        today we have a lot of knowledge and experience, which not only makes us
        reliable but has also made us a market leader in our niche product
        category.
      </p>
      <div className="rectangle-container">
        <div className="mb-5">
          <div
            onMouseEnter={() => setRec(1)}
            className={rec === 1 ? "rectangle-selected" : "rectangle"}
          >
            <img src={icon1} className="rectangle-icon" />
          </div>
          <p className="rectangle-text">
            Regulation <br /> Compliance
          </p>
        </div>
        <div className="mb-5">
          <div
            onMouseEnter={() => setRec(2)}
            className={rec === 2 ? "rectangle-selected" : "rectangle"}
          >
            <img src={icon2} className="rectangle-icon" />
          </div>
          <p className="rectangle-text">
            Colour <br /> & Identification
          </p>
        </div>
        <div className="mb-5">
          <div
            onMouseEnter={() => setRec(3)}
            className={rec === 3 ? "rectangle-selected" : "rectangle"}
          >
            <img src={icon3} className="rectangle-icon" />
          </div>
          <p className="rectangle-text">
            Brand <br /> Protection
          </p>
        </div>
        <div className="mb-5">
          <div
            onMouseEnter={() => setRec(4)}
            className={rec === 4 ? "rectangle-selected" : "rectangle"}
          >
            <img src={icon4} className="rectangle-icon" />
          </div>
          <p className="rectangle-text">
            Fiscal <br /> Protection
          </p>
        </div>
      </div>

      {/* new section */}
      {/* <div className="rectangle-container">
        <div className="mb-5">
          <div className="home-our-service-img">
         <img src={img1} alt=''/>
          </div>
          <p className="rectangle-text">Board Games Kit</p>
        </div>
        <div className="mb-5">
          <div className="home-our-service-img">
          <img src={img2} alt=''/>
          </div>
          <p className="rectangle-text">Ipad Giving</p>
        </div>
        <div className="mb-5">
          <div className="home-our-service-img">
          <img src={img3} alt=''/>
          </div>
          <p className="rectangle-text">Save Trees

</p>
        </div>
      </div> */}
      {/* <div className="service-title">
        <h3>Lorem Ipsum</h3>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque
          scelerisque posuere euismod. Aliquam sit amet. Lorem ipsum dolor sit
          amet, consectetur adipiscing elit. Quisque scelerisque posuere
          euismod. Aliquam sit amet.
        </p>
      </div>
      <div className="service-title">
        <h3>Lorem Ipsum</h3>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque
          scelerisque posuere euismod. Aliquam sit amet. Lorem ipsum dolor sit
          amet, consectetur adipiscing elit. Quisque scelerisque posuere
          euismod. Aliquam sit amet.
        </p>
      </div> */}

      {/* <div className="rectangle-two-container ">
        <div className="rectangle-two">
          <h1>Lorem Ipsum</h1>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. </p>
        </div>
        <div className="rectangle-two">
          {" "}
          <h1>Lorem Ipsum</h1>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. </p>
        </div>
        <div className="rectangle-two">
          {" "}
          <h1>Lorem Ipsum</h1>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. </p>
        </div>
      </div> */}
    </section>
  );
}
