import React, { useState } from "react";
import styled from "styled-components";

const ProgressBar = ({ done}) => {
  const [style, setStyle] = useState({});

  setTimeout(() => {
    const newStyle = {
      opacity: 1,
      width: `${done}%`,
    };

    setStyle(newStyle);
  }, 2000);

  return (
    <Main>
      <Progress>
        <ProgressDone style={style}>{done}%</ProgressDone>
      </Progress>
    </Main>
  );
};

const Main = styled.div`
  display: flex;
  margin-left: 50px;
`;



const Progress = styled.div`
  background-color: #d8d8d8;
  border-radius: 20px;
  position: relative;
  height: 30px;
  width: 100%;
  @media (max-width: 500px) {
    height: 20px;
  }
`;
const ProgressDone = styled.div`
  background: #f1f152;
  border-radius: 20px;
  color: #000000;
  display: flex;
  font-weight: 600;
  font-size: 15px;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 0;
  opacity: 0;
  transition: 1s ease 0.3s;
`;

export default ProgressBar;