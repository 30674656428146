import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faEnvelope, faCity } from "@fortawesome/free-solid-svg-icons";
import { FaLinkedin } from "react-icons/fa";
import AOS from "aos";
import "aos/dist/aos.css";
import axios from "axios";

export default function Footer() {
  const [data, setData] = useState({
    name: "",
    email: "",
    product: "",
    company: "",
    message: "",
  });
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const handleSubmit = (e) => {
    e.preventDefault()
    if (data.name && data.email && data.message) {
      axios
        .post(
          "https://sheet.best/api/sheets/efe64c8a-5281-4bd5-91df-778140ce53dc",
          data
        )
        .then((res) => {
          setError("");
          setSuccess("Your information sent successfully");
          setTimeout(() => {
            setSuccess("");
          }, 10000);
          console.log(res)
          setData({
            name: "",
            email: "",
            product: "",
            company: "",
            message: "",
          });
        });
    } else {
      setSuccess("");
    
      setError("Please fill up missing field");
    }

    // e.preventDefault()
  };

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
     console.log(e.target.name,data)
  };
  useEffect(() => {
    AOS.init({
      duration: 1700,
    });
    // AOS.refresh();
  }, []);


  const handleSurat=()=>{
    window.location.href='https://www.google.com/maps/search/?api=1&Plot+No+262%2C+Sachin%2C+G+I+D+C+G+I+D%2C+Surat%2C+Gujarat%2C+394230%2C+India'
  }
  return (
    <footer className="container-custom footer-container">
      <div className="row m-0 mt-4">
        <div className="col-sm-7 col-md-6 col-lg-5">
          <h5>Contact Us</h5>
          {error && <p className="error-text">{error}</p>}
          {success && <p className="success-text">{success}</p>}
          <form onSubmit={handleSubmit}>
            <label for="name">Name</label> <br />
            <input
              onChange={handleChange}
              type="text"
              id="name"
              name="name"
              value={data.name}
            />{" "}
            <br />
            <label for="email">Email</label> <br />
            <input
              onChange={handleChange}
              type="text"
              id="email"
              name="email"
              value={data.email}
            />{" "}
            <br />
            <label for="message">Message</label> <br />
            <textarea
              onChange={handleChange}
              type="text"
              id="message"
              name="message"
              value={data.message}
            />{" "}
            <br />
            <button  className="footer-submit">
              Submit
            </button>
          </form>
        </div>
        <div className="col-sm-5 col-md-6 col-lg-4" >
          <h5> Factory in Surat</h5>

          {/* <a className="footer-link" href='https://g.co/kgs/Rzk2fa' target='_blank' rel="noreferrer">
            {" "}
            <FontAwesomeIcon icon={faCity} /> Plot No 262, Sachin, G I D C,<br/> Surat, Gujarat 394230, India, Surat, Gujarat 394230, India
          </a> */}
          <p className="footer-link" href='https://g.co/kgs/Rzk2fa' target='_blank' rel="noreferrer">
            {" "}
            <FontAwesomeIcon icon={faCity} /> Plot No 262, Sachin, G I D C,<br/> Surat, Gujarat 394230, India
          </p>

          <p>
            <FontAwesomeIcon icon={faPhone} />  +91 22 61433030
          </p>
          <p>
            <FontAwesomeIcon icon={faEnvelope} /> info@chemocid.com
          </p>
        </div>

        <div className="col-sm-5 col-md-6 col-lg-3">
          {/*  */}
        <h5>Office in Mumbai </h5>
        <a className="footer-link" href='https://www.google.com/maps/search/?api=1&query=Eureka+Towers/@19.1800213,72.8342819,17z/data=!4m9!1m2!2m1!1sEureka+Tower!3m5!1s0x3be7b6f15d8d55c1:0x82dc372c1dee3a1e!8m2!3d19.1800469!4d72.8342861!15sCgxFdXJla2EgVG93ZXJaDiIMZXVyZWthIHRvd2VykgEPYnVzaW5lc3NfY2VudGVymgEkQ2hkRFNVaE5NRzluUzBWSlEwRm5TVU50TlUweVNYSm5SUkFC4AEA?hl=en' target='_blank' rel="noreferrer">
            {" "}
            <FontAwesomeIcon icon={faCity} />  801/A, 8th Flr, Eureka Towers, Mind Space, Goregaon Malad Link Rd, Malad (W), Mumbai- 400064  
          </a>
          <p>
            <FontAwesomeIcon icon={faPhone} />  +91 22 61433030
          </p>
          <p>
            <FontAwesomeIcon icon={faEnvelope} /> info@chemocid.com
          </p>
          {/* <h5>Follow Us</h5> */}
          {/* <p className="lets-connect">
            <FaLinkedin /> Let’s Connect
          </p> */}
        </div>
      </div>
    </footer>
  );
}
