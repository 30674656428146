import React, { useEffect, useState } from "react";
import "../styles/Product.css";
import p1 from "../assets/images/p1.png";
import p2 from "../assets/images/p2.png";
import p3 from "../assets/images/p3.png";
import p4 from "../assets/images/p4.png";
import p5 from "../assets/images/p5.png";
import p6 from "../assets/images/p6.png";
import PieChartComponent from "../components/Product/PieChartComponent";
import AOS from "aos";
import "aos/dist/aos.css";
import { productsList } from "../Product";

export default function Product() {
  const [displaydata, setDisplayData] = useState([]);
  const [chartLabel, setChartLabel] = useState("");
  const [chartElement, setChartElement] = useState([]);
  const [name,setName] = useState("")
  const [data, setData] = useState({
    name: "",
    product: "",
  });
  useEffect(() => {
    AOS.init({
      duration: 1700,
    });
    // AOS.refresh();
  }, []);

  useEffect(() => {
     let n = displaydata[0]?.name.split(" ")[1];
     setName(n)
  },[displaydata[0]])

  useEffect(() => {
    if (chartLabel && chartElement.length > 0) {
      const filterData = productsList.filter((d) => d.color === chartLabel);
      setDisplayData(filterData);
    } else {
      setDisplayData(productsList);
    }
  }, [chartLabel, chartElement.length]);
  // console.log(productsList);
  return (

      <div className="product-container container-custom">
        <h1>Our Products</h1>

        <div className="chart-container">
          <PieChartComponent
            setChartLabel={setChartLabel}
            setChartElement={setChartElement}
          />
          <div id="center-text" className="text-white">{name}</div>
        </div>

        <div className="circle-text">
          <span style={{ background: `${chartLabel}` }}></span>
        </div>
        {/* <div id="colorWheel">
  <span class="color01"></span>
  <span class="color02"></span>
  <span class="color03"></span>
  <span class="color04"></span>
  <span class="color05"></span>
  <span class="color06"></span>
  <span class="color07"></span>
  <span class="color08"></span>
  <span class="color09"></span>
  <span class="color10"></span>
</div> */}
        <div
          data-aos="fade-up"
          data-aos-duration="1700"
          className="product-item-container"
        >
          {displaydata.map((d) => {
            return (
              <div
                onClick={() => setData({ ...data, product: d.name })}
                className="product-item"
              >
                <div>
                  {" "}
                  <img src={d?.image} alt="" />
                </div>
                <p>{d?.name} </p>
              </div>
            );
          })}
          {/* <div className="product-item">
          <div>
            {" "}
            <img src={'/images/p1.png'}alt="" />
          </div>
          <p>Product Name</p>
        </div>
        <div className="product-item">
          <div>
            {" "}
            <img src={p2} alt="" />
          </div>
          <p>Product Name</p>
        </div>
        <div className="product-item">
          {" "}
          <div>
            {" "}
            <img src={p3} alt="" />
          </div>
          <p>Product Name</p>
        </div>
        <div className="product-item">
          {" "}
          <div>
            {" "}
            <img src={p4} alt="" />
          </div>
          <p>Product Name</p>
        </div>
        <div className="product-item">
          {" "}
          <div>
            {" "}
            <img src={p5} alt="" />
          </div>
          <p>Product Name</p>
        </div>
        <div className="product-item">
          {" "}
          <div>
            {" "}
            <img src={p6} alt="" />
          </div>
          <p>Product Name</p>
        </div> */}
        </div>
        <div data-aos="fade-up" data-aos-duration="1700" className="buy-info">
          <div>
            <input placeholder="Name" type="text" id="name" name="name" />
            <input
              placeholder="Company"
              type="text"
              id="company"
              name="company"
            />
            <input
              placeholder="Product"
              type="text"
              id="product"
              name="product"
              value={data.product}
            />
          </div>
          <button className="product-buy-now">Buy Now</button>
        </div>
      </div>
  );
}
