import React, { useEffect } from "react";
import bannerImg from "../../assets/images/banner-img.gif";
import AOS from 'aos';
import "aos/dist/aos.css";

export default function Banner() {
  useEffect(()=>{
    AOS.init({
      duration : 1700
    });
    // AOS.refresh();
  },[])
 
  return (
    <div data-aos="fade-up" data-aos-duration="1700" className="container-custom">
      <div className="banner-container">
        <div className="banner-content">
          <h1>
            CHEMOCID IMPEX PVT LTD
          </h1>
          <p>
            {/* Your Specialty Chemical Solution Provider{" "} */}
            Customizable solutions for your chemical needs

          </p>
        </div>
        <div className="banner-img">
          <img src={bannerImg} alt="" />
        </div>
      </div>
    </div>
  );
}
