import React, { useEffect } from "react";
import Footer from "../components/common/Footer";
import Navigation from "../components/common/Navigation";
import Banner from "../components/Home/Banner";
import OurGlobalPresence from "../components/Home/OurGlobalPresence";
import OurMotto from "../components/Home/OurMotto";
import OurService from "../components/Home/OurService";
import Products from "../components/Home/Products";
import "../styles/Home.css";
import AOS from 'aos';
import "aos/dist/aos.css";

export default function Home() {

  useEffect(()=>{
    AOS.init({
      duration : 1700
    });
    // AOS.refresh();
  },[])
  return (
    <div>
      {/* <Navigation /> */}

      <Banner />

      <OurGlobalPresence/>
      <OurMotto/>
      <Products/>
    
      <OurService/>

      {/* <Footer/> */}
    </div>
  );
}
